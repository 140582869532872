import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AsyncSubject, forkJoin, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MODALS_CONFIG } from '../configuration/modals-configuration';
import { ModalsConfiguration } from '../configuration/modals-configuration.model';
import { PriceTypeService } from "../configuration/standard-services/price-type.service";

import { AppConfiguration } from "../configuration/app-configuration.model";
import { DVM_CONFIG } from "../configuration/dvm-configuration";
import { SEATGEEK_CONNECTION_CONFIG, SeatgeekConnectionConfiguration } from 'ngx-seatgeek-connection';

// Will be removed and fetched via HTTP in a future version
import { APP_CONFIG } from '../configuration/app-configuration';
import { DVMConfiguration } from '../configuration/dvm-configuration.model';
import { DescriptionsService } from './descriptions.service';
import { FlowControlService } from './flow-control.service';


import * as configurationJSON from "../configuration/configuration.json";

@Injectable({
  providedIn: 'root'
})
export class FetcherService {

  private dataSemaphore$ = new AsyncSubject<void>();

  get onDataFetched$() {
    return this.dataSemaphore$.asObservable();
  }

  constructor(private http: HttpClient, private priceTypeService: PriceTypeService,
    @Inject(APP_CONFIG) private appConfig: AppConfiguration,
    @Inject(DVM_CONFIG) private dvmConfig: DVMConfiguration,
    @Inject(SEATGEEK_CONNECTION_CONFIG) private seatgeekConfig: SeatgeekConnectionConfiguration,
    @Inject(MODALS_CONFIG) private modalsConfig: ModalsConfiguration,
    private descriptionsService: DescriptionsService) { }



  // Receives the client ID and fetch all its associated resources into the services
  fetchData(client) {


    const catcher = (fileName, logType) => catchError((_) => {
      console[logType](`${fileName} is not available for this client`);
      return of(null)
    })


      const pricingJSON$ = this.http.get(`/assets/seatgeekdataconfig/pricing/pricing.json`)
                            .pipe(catcher("Pricing file", "error"));

      // const pricingRenewalJSON$ = this.http.get(`/assets/seatgeekdataconfig/pricing/pricing_renewal.json`)
      const pricingRenewalJSON$ = this.http.get(`/assets/seatgeekdataconfig/pricing/pricing_permatch.json`)
                            .pipe(catcher("Pricing file", "error"));

      const seatPriceTypeJSON$ = this.http.get(`/assets/seatgeekdataconfig/seat-priceType.json`)
                            .pipe(catcher("PriceType per seat file", "log"));

      const customPriceTypesJSON$ = this.http.get(`/assets/seatgeekdataconfig/customPriceTypes.json`)
                            .pipe(catcher("Custom PriceTypes file", "log"));

      const descriptionsJSON$ = this.http.get(`/assets/seatgeekdataconfig/descriptions.json`)
                            .pipe(catcher("Area descriptions file", "log"));

      const popoverHeadersJSON$ = this.http.get(`/assets/seatgeekdataconfig/popover-headers.json`)
                            .pipe(catcher("Area descriptions file", "log"));




      forkJoin([pricingJSON$, pricingRenewalJSON$, seatPriceTypeJSON$, customPriceTypesJSON$, descriptionsJSON$, popoverHeadersJSON$])
        .subscribe(([seatpricing, seatpricingRenewal, seatPriceType, customPriceTypes, descriptions, popoverHeaders]) => {

          const configuration = configurationJSON["default"][client];

          for (const [key, value] of Object.entries(configuration["app-configuration"])) {
            this.appConfig[key] = value;
          }
          for (const [key, value] of Object.entries(configuration["dvm-configuration"])) {
            this.dvmConfig[key] = value;
          }
          for (const [key, value] of Object.entries(configuration["seatgeek-configuration"])) {
            this.seatgeekConfig[key] = value;
          }




          this.priceTypeService.seatpricing = seatpricing || undefined;
          this.priceTypeService.seatpricingRenewal = seatpricingRenewal || undefined;
          this.priceTypeService.priceTypesBySeat = seatPriceType || undefined;
          this.priceTypeService.customPriceTypes = customPriceTypes || undefined;
          this.descriptionsService.descriptions = descriptions || { };
          this.descriptionsService.popoverHeaders = popoverHeaders || { }



          this.dataSemaphore$.next();
          this.dataSemaphore$.complete();
        });
  }
}
