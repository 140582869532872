import { Injectable, Inject } from '@angular/core';
declare var $: any;
import * as $ from 'jquery';
import { APP_CONFIG } from "../configuration/app-configuration";
import { AppConfiguration } from "../configuration/app-configuration.model";
import { SeatgeekConnectionIdtranslatorService, SeatgeekConnectionSeriesService } from 'ngx-seatgeek-connection';
import { FileParserSectioninareasOutput } from 'projects/ngx-seatgeek-connection/src/lib';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class FlowControlService {

    public current3DView;
    private sectionsInAreas: boolean | FileParserSectioninareasOutput = false;
    public maxAddons;
    public relocationSeatsCount = 0;
    public availabilityLoaded: Subject<any> = new Subject();

    public seriesId;
    public seriesName;


    appointmentTime;

    public suitesHash = {};
    public selectSeatFunction;

    public canSeatSelect: boolean = false

    get currentStep(): string {
        // if (this.status.blockmap.visible && this.status.blockmap.size === 'large') { return 'blockmap'; }
        // if (this.status.seatmap.visible && this.status.seatmap.size === 'large') { return 'seatmap'; }
        // if (this.status.view3d.visible) { return 'view3d'; }
        return '';
        console.log('Error in get flowStep, FlowControlService.');
    }

    get isGASelected() {
        let isGASelected = false;
        this.translator.sectionsInAreas.subscribe(
            _sectionsInAreas => {
                this.sectionsInAreas = _sectionsInAreas;
            }
        );
        if (this.sectionsInAreas) {
            if (this.app_config.isAreaMap) {
                isGASelected = this.app_config.generalAdmissionAreas.indexOf(this.sectionsInAreas['sectors'][this.current3DView]) >= 0;
            } else {
                isGASelected = this.app_config.generalAdmissionAreas.indexOf(this.current3DView) >= 0;
            }
        }
        return isGASelected;
    }

    constructor(@Inject(APP_CONFIG) private app_config: AppConfiguration,
                private translator: SeatgeekConnectionIdtranslatorService,
                private connection: SeatgeekConnectionSeriesService) {
            // this.connection.getAreaAvailableSeatsCount().subscribe();
            this.maxAddons = this.app_config.addonsConfiguration.addonsMaxSelection;
        }

    showLoader(val: boolean) {
    }

}
