import { Injectable, Inject } from "@angular/core";
import { DVMService } from "../services/dvm.service";
import { GeneralHandler } from "./general.handler";
import { DVM_CONFIG } from "../configuration/dvm-configuration";
import { DVMConfiguration } from "../configuration/dvm-configuration.model";
import { FlowControlService } from "../services/flow-control.service";
import {
  SeatgeekConnectionSeriesService,
  SeatgeekConnectionIdtranslatorService,
  SeriesItem,
} from "ngx-seatgeek-connection";
import { ModalsService } from "../services/modals.service";
import { APP_CONFIG } from "../configuration/app-configuration";
import { AppConfiguration } from "../configuration/app-configuration.model";
import { LoaderService } from "../services/loader.service";
import { SeatgeekConnectionService } from "../services/seatgeek-connection.service";
// import { PricingService } from '../services/pricing.service';

@Injectable()
export class LoadHandler extends GeneralHandler {
  constructor(
    @Inject(APP_CONFIG) private appConfig: AppConfiguration,
    @Inject(DVM_CONFIG) private dvmConfig: DVMConfiguration,
    private flowControl: FlowControlService,
    private translator: SeatgeekConnectionIdtranslatorService,
    private modals: ModalsService,
    private seatgeekConnectionService: SeatgeekConnectionService,
    // private pricing: PricingService,
    private connection: SeatgeekConnectionSeriesService,
    protected dvmService: DVMService,
    private loaderService: LoaderService
  ) {
    super("end_load", dvmService);
  }

  loadBlockmapHandler(viewer) {
    // this.flowControl.showLoader(true);

    if (!this.loaderService.isLoadingApplication) {
      this.loaderService.addProcess("previewblockmap");
    }
    viewer.setAvailability([]);
    this.connection.getAreaAvailableSeatsCount().subscribe(
      (responseCount) => {},
      (error) => {
        console.log(error);
      }
    );
    this.seatgeekConnectionService.getGeneralAvailability().subscribe(
      (generalAvailability) => {
        if (generalAvailability != null) {
          //viewer.setAvailable("section", viewer.getNodesByType("section"));
          viewer.setAvailable("section", generalAvailability);
          viewer.setAvailable("section", Object.keys(this.flowControl.suitesHash));
          viewer.setNodesTag(Object.keys(this.flowControl.suitesHash), "vip");
          // status.blockmap.moduleRef['setElementUnavailable'](this.lockedAreas);
        }
        // this.flowControl.showLoader(false);
        if (!this.loaderService.isLoadingApplication) {
          this.loaderService.removeProcess("previewblockmap");
        } else {
          this.loaderService.fadeIn();
        }
      },
      (error) => {
        console.error("error in getGeneralAvailability", error);
        this.modals.throwErrorModal(error.message);
        // this.flowControl.showLoader(false);
        if (!this.loaderService.isLoadingApplication) {
          this.loaderService.removeProcess("previewblockmap");
        } else {
          this.loaderService.fadeIn();
        }
      }
    );
  }

  loadSeatmapHandler(viewer) {
    // this.flowControl.showLoader(true);
    this.loaderService.addProcess("previewseatmap");
    this.loadMinimap(viewer.getMapId());
    viewer.setAvailability([]);
    this.translator.translationsHolder.subscribe(
      (translationsHolderData) => {
        this.loaderService.removeProcess("previewseatmap");
        this.loaderService.addProcess(["loadAreaAvailability", "loadAreaBasket"]);
        const seatmapId = viewer.getMapId();
        const areaId = translationsHolderData["frommmc"]["areas"][seatmapId]["seatgeekId"];
        this.connection.getAreaAvailability(areaId).subscribe((areaAvailability) => {
          if (areaAvailability != null) {
            viewer.setAvailable("seat", areaAvailability);
          }
          // this.flowControl.showLoader(false);
          this.loaderService.removeProcess("loadAreaAvailability");
        });

        this.connection.getBasketItems().subscribe((basketItems: SeriesItem[]) => {
          const basketSeats = basketItems.map((basketItem: SeriesItem) => basketItem.productData.mmcSeatId);
          viewer.setAvailable("seat", basketSeats, seatmapId);
          viewer.select(basketSeats);
          this.loaderService.removeProcess("loadAreaBasket");
        });
      },
      (error) => {
        console.error("Error loading ID translations.");
        this.modals.throwErrorModal(error.message);
        // this.flowControl.showLoader(false);
        this.loaderService.removeProcess("previewseatmap");
      }
    );
  }

  loadMinimap(node) {
    this.dvmService.viewer_mini.unselectAll();
    this.dvmService.viewer_mini.select(node);
    this.dvmService.viewer_mini.goTo(node, this.dvmService.viewer_mini.min_scaling_factor * 2.5, 1000);
  }

  protected handle(error, viewer) {
    if (error) {
      console.error(error);
    }
    viewer = viewer.instance;
    if (viewer.getMapId() === this.dvmConfig.map_id) {
      this.loadBlockmapHandler(viewer);
    } else {
      this.loadSeatmapHandler(viewer);
    }
  }
}
