import { Injectable, Inject } from '@angular/core';
import { DVMService } from '../services/dvm.service';
import { GeneralHandler } from './general.handler';
import { DVM_CONFIG } from "../configuration/dvm-configuration";
import { DVMConfiguration } from "../configuration/dvm-configuration.model";
import { createPopper, Modifier, Placement } from '@popperjs/core';
import * as $ from 'jquery';
import { FlowControlService } from '../services/flow-control.service';
import { Popover3dviewService } from '../services/popover-3dview.service';
import { SeatgeekConnectionSeriesService } from 'ngx-seatgeek-connection';
import { PriceTypeService } from '../configuration/standard-services/price-type.service';
import { DescriptionsService } from '../services/descriptions.service';
import { APP_CONFIG } from '../configuration/app-configuration';
import { AppConfiguration } from '../configuration/app-configuration.model';
// import { PricingService } from '../services/pricing.service';

@Injectable()
export class PopoverCreateHandler extends GeneralHandler {

    tooltip: HTMLElement;
    view3DId;

    constructor(protected dvmService: DVMService,
                private pricing: PriceTypeService,
                private connection: SeatgeekConnectionSeriesService,
                private flowControl: FlowControlService,
                private popover3dview: Popover3dviewService,
                private descriptionsService: DescriptionsService,
                @Inject(APP_CONFIG) private appConfig: AppConfiguration,
                @Inject(DVM_CONFIG) private dvmConfig: DVMConfiguration) {
        super('enter', dvmService);
    }

    protected handle(obj) {
        if (document.querySelector('#tooltip')) {
            this.popover3dview.deleteTimer();
            if (obj.nodes.length && (obj.nodes[0].state === 'available' || obj.nodes[0].state === 'selected')) {
                const node = obj.nodes[0];
                this.popover3dview.currentView = node.id;
    
                // Receive tooltip HTML Element
                this.tooltip = document.querySelector('#tooltip');
                // console.log(this.tooltip);
    
                // Generate tooltip content based on node type
                let tooltipContent;
                
                // Content flags
                const {includeDescription, includePricing, includeSeatsCount} = this.appConfig.popoversConfiguration;

                let description = includeDescription && this.descriptionsService.getDescriptionOf(node);
                let header = this.descriptionsService.getHeaderOf(node);

                let price;
                let price_renewal;

                if (node.type === 'section' && obj.instance.getMapId() === this.dvmConfig.map_id) {
                    let seatsCount;
                    if (includeSeatsCount && this.connection.availableSeatsCount && this.connection.availableSeatsCount[node.id]) {
                        seatsCount = this.connection.availableSeatsCount[node.id];
                    }
                    if (includePricing && this.pricing.seatpricing[node.id]) {
                        price = this.pricing.seatpricing[node.id];
                    }
                    tooltipContent = this.sectionPopoverContent(node, description, price, seatsCount, header);
                } else if (node.type === 'seat') {
                    if (includePricing && this.pricing.seatpricing[node.id]) {
                        price = this.pricing.seatpricing[node.id];
                    }
                    if (includePricing && this.pricing.seatpricingRenewal[node.id]) {
                        price_renewal = this.pricing.seatpricingRenewal[node.id];
                    }
                    tooltipContent = this.seatPopoverContent(node, description, price, price_renewal);
                }
    
                // Create content HTML Element, clean previous popover and append
                const tooltipContentHTML = document.createElement('div');
                tooltipContentHTML.innerHTML = tooltipContent.trim();
                const popoverBodyNode = document.querySelector('#tooltip .popover-body');
                while (popoverBodyNode.firstChild) {
                    popoverBodyNode.removeChild(popoverBodyNode.firstChild);
                }
                popoverBodyNode.appendChild(tooltipContentHTML);
    
                // Request and place thumbnail
                this.dvmService.viewer3d.getThumbnail({ venue_id: this.dvmService.viewer.getVenueId(),
                    view_id: node.id }, true).then((img) => {
                    $('.ticket-selected-view-img').html('');
                    $('.ticket-selected-view-img').css('background-image', 'url("' + img + '")');
                });
    
                // Calculate popover placement
                let placement: Placement;
                if (node.anchor.dom[1] > (document.getElementById('main-interface').offsetHeight / 2)) {
                    placement = 'top';
                } else {
                    placement = 'bottom';
                }
    
                // Initiate popover
                createPopper(node, this.tooltip, {
                    placement,
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, 8],
                            },
                        },
                        {
                            name: 'flip',
                            options: {
                                fallbackPlacements: [],
                            }
                        }
                    ],
                });
    
                // Display popover
                this.tooltip.setAttribute('data-show', '');
            }
        }
    }

    open3DView() {
        this.dvmService.load3DView(this.view3DId);
      }

    sectionPopoverContent(node, description?, price?, seatsCount?, header?) {
        const areaName = node.id.split('_')[1].split('(')[0];

        let tooltipContent = "";

        if (header) {
            tooltipContent += `<div class="text-center" style="margin: .25rem 0; color: #fff"> 
                ${header}
            </div>`
        }
        
        tooltipContent += `<div class='ticket-selected-view'>
            <div class='ticket-selected-view-img'></div>
        </div>
        <ul class='list-unstyled ticket-selected'>
            <span class='ticket-val'>Click to open view</span>
            <li style='text-align: center;'>
                <span class='ticket-lab'>Section</span>
                <span class='ticket-val'>` + areaName + `</span>
            </li>
        </ul>`;
        if (price || seatsCount) {
            tooltipContent += "<ul class='list-unstyled ticket-selected-seat flex'>"

            if (price) {
                tooltipContent += `<li style='text-align: center; display: block;'>
                <span class='ticket-lab'>Price</span>
                <span class='ticket-val'>` + price + `</span>
            </li>`;
            }

            if (seatsCount) {
                tooltipContent += `<li style='text-align: center; display: block;'>
                <span class='ticket-lab'>Seats Available</span>
                <span class='ticket-val'>` + seatsCount + `</span>
            </li>`
            }
            tooltipContent += "</ul>"
        }
        // if (price) {
        //     tooltipContent += `<ul class='list-unstyled ticket-selected'>
        //     <li style='text-align: center;'>
        //         <span class='ticket-lab'>Price</span>
        //         <span class='ticket-val'>` + price + `</span>
        //     </li>
        // </ul>`;
        // }
        // if (seatsCount) {
        //     tooltipContent += `<ul class='list-unstyled ticket-selected'>
        //     <li style='text-align: center;'>
        //     <span class='ticket-lab'>Seats Available</span>
        //     <span class='ticket-val'>` + seatsCount + `</span>
        //     </li>
        // </ul>`;
        // }
        if (description) {
            tooltipContent += '<ul class=\'list-unstyled ticket-selected-seat\'>' +
            '<li style=\'display:block;\'>' +
            '<span class=\'ticket-description\'>' + description + '</span> ' +
            '</li>' +
            '</ul>';
        }
        return tooltipContent;
    }

    seatPopoverContent(node, description?, price?, price_renewal?) {
        const seating = node.id.split('-');
        const section = seating[0].split('_')[1].split('(')[0];
        const row = seating[1];
        const seat = seating[2];
        let tooltipContent = `<div class='ticket-selected-view'>
            <div class='ticket-selected-view-img'></div>
        </div>
        <ul class='list-unstyled ticket-selected'>
            <li>
                <span class='ticket-val'>Click to open view</span>
            </li>
        </ul>
        <ul class='flex ticket-selected-seat'>
        <li style='display:block;'>
                <span class='ticket-lab'>Section</span>
                <span class='ticket-val'>` + section + `</span>
            </li>
            <li>
                <span class='ticket-lab'>Row</span>
                <span class='ticket-val'>` + row + `</span>
            </li>
            <li>
                <span class='ticket-lab'>Seat</span>
                <span class='ticket-val'>` + seat + `</span>
            </li>
        </ul>`;
        if (price) {
            tooltipContent += `<ul class='flex ticket-selected-seat'>
            <li style='display:block;'>
                <span class='ticket-lab'>Price</span>
                <span class='ticket-val'>` + price + `</span>
            </li>
        </ul>`;
        }
        if (price_renewal) {
            tooltipContent += `<ul class='flex ticket-selected-seat'>
            <li style='display:block;'>
                <span class='ticket-lab'>Per match</span>
                <span class='ticket-val'>` + price_renewal + `</span>
            </li>
        </ul>`;
        }
        if (description) {
            tooltipContent += '<ul class=\'list-unstyled ticket-selected-seat\'>' +
            '<li style=\'display:block;\'>' +
            '<span class=\'ticket-description\'>' + description + '</span> ' +
            '</li>' +
            '</ul>';
        }
        return tooltipContent;
    }
}
