import { Injectable } from '@angular/core';
import { SeatgeekConnectionSeriesService } from 'ngx-seatgeek-connection';
import { ModalsService } from '../services/modals.service';
declare var $: any;
import * as $ from 'jquery';
import { DVMService } from './dvm.service';
import { FlowControlService } from './flow-control.service';
import { LoaderService } from './loader.service';
import { SeatgeekConnectionService } from './seatgeek-connection.service';
import { buffer, catchError, tap } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

/* ISOLATED SEAT HANDLING */

export class IsolatedSeatsService {

  singleSeatsBuffer = [];
  singleSeatsAction = '';

  constructor(private connection: SeatgeekConnectionSeriesService,
    private dvmService: DVMService,
    private flowControl: FlowControlService,
    private modals: ModalsService,
    private seatgeekConnectionService: SeatgeekConnectionService,
    private loaderService: LoaderService) { }

  public checkPendingIsolatedSeats() {
    const pendingElements = this.dvmService.viewer.getNodesByTag('seat', 'pending');
    return this.checkIsolatedSeats(pendingElements);
  }

  public checkIsolatedSeats(seats, seatObject = null, action = null) {
    const isolatedSeats = [];
    for (let i = 0; i < seats.length; ++i) {
      const seat = seats[i];
      if (seat.row) {
        const row = seat.row;
        const index = row.indexOf(seat.id);
        const right_id1 = row[index + 1];
        const right_id2 = row[index + 2];
        const left_id1 = row[index - 1];
        const left_id2 = row[index - 2];

        if (this.isAvailable(right_id1)) { // Si hay uno disponible a la derecha
          if (!this.isAvailable(right_id2)) { // Si no hay uno disponible 2 a la derecha
            isolatedSeats.push(right_id1); // El de la derecha está aislado
          }
        }

        if (this.isAvailable(left_id1)) { // Si hay uno disponible a la izquierda
          if (!this.isAvailable(left_id2)) { // Si no hay uno disponible 2 a la izquierda
            isolatedSeats.push(left_id1); // El de la izquierda está aislado
          }
        }
      }
    }
    if (isolatedSeats.length > 0 && action != null && seatObject != null) {
      this.singleSeatHandling(seatObject, seatObject.id, action);
    }
    return isolatedSeats;
  }

  
  private isAvailable(id) {
    if (id) {
      const element = this.dvmService.viewer.getNodeById(id);
      return element.state === "available";
    }
    return false; // Si no existe, no es available
  }


  /*
  * CHECK IF BUFFER NEEDS TO BE RESET
  */
  public checkResetBuffer(areaid) {
    if (this.singleSeatsBuffer.length > 0 && this.singleSeatsAction === 'add') {
      // AREAID CHANGE CONDITION
      if (areaid !== this.singleSeatsBuffer[0]['areaid']) {
        this.singleSeatsBuffer = [];
      }
    }
  }

  /*
  * RESET BUFFER
  */
  public resetBuffer() {
    this.singleSeatsBuffer = [];
    this.singleSeatsAction = '';
  }

  /*
  * TO BE EXECUTED WHEN A SEAT IS UNSELECTED AND THE ISOLATED SEATS BUFFER IS NOT EMPTY
  */
  public singleSeatHandling(seatObject, mmcid, action) {
    // IF CURRENT ACTION IS NOT 'REMOVE'
    if (this.singleSeatsAction !== action) {
      if (this.singleSeatsBuffer.length > 0) {
        // IF SEAT SELECTED IS ON THE BUFFER, WE REMOVE IT
        if (this.dvmService.viewer.getNodeById(mmcid).tag === 'pending') {
          this.singleSeatsBuffer = [];
        } else {
          // IF NOT, WE SEND WARNING TO THE USER
          const errorMessage = 'IsolatedSeatsNotHandled';
          const errorText = 'When making seat selections, you cannot leave a single seat. ' +
            'Please adjust your seating selection to continue.';
          this.modals.throwErrorModal(errorMessage, errorText);
        }
        // PRINT BUFFER UPDATES ON THE MAP
        this.printBuffer();
        // this.flowControl.showLoader(false);
        this.loaderService.removeProcess("selectseat");
        return of(undefined);
        // IF BUFFER IS EMPTY, WE SET THE NEW ACTION
      } else if (this.singleSeatsBuffer.length === 0) {
        this.singleSeatsAction = action;
      }
    }
    // CREATE THE SINGLE SEAT OBJECT FOR THE CURRENT ACTION
    let singleSeat = {};
    if (this.singleSeatsAction === 'remove') {
      singleSeat = { 'itemid': seatObject['itemid'], 'mmcid': mmcid };
    } else {
      singleSeat = {
        'areaid': seatObject['areaid'], 'pricetype': seatObject['pricetype'],
        'seat': seatObject['seat'], 'mmcid': mmcid
      };
    }
    // IF THE ISOLATED SEATS BUFFER IS EMPTY, WE ADD THE SEAT TO THE BUFFER
    if (this.singleSeatsBuffer.length <= 1) {
      this.singleSeatsBuffer.push(singleSeat);
    } else {
      // IF THE BUFFER ALREADY CONTAINS TWO SEATS, WARNING IS SENT TO THE USER
      const errorMessage = 'IsolatedSeatsNotHandled';
      const errorText = 'When making seat selections, you cannot leave a single seat. ' +
        'Please adjust your seating selection to continue.';
      this.modals.throwErrorModal(errorMessage, errorText);
    }
    // IF THE BUFFER CONTAINS TWO SEATS, THEY ARE SENT TO BE RESOLVED
    let bufferAction$ = of(undefined);
    if (this.singleSeatsBuffer.length >= 2) {
      if (this.singleSeatsAction === 'remove') {
        bufferAction$ = this.sendSingleSeatsRemoveBuffer();
      } else {
        bufferAction$ = this.sendSingleSeatsAddBuffer();
      }
    }
    // 'PENDING' STATUS IS ADDED TO THE SEATS IN THE BUFFER
    // for (var i = 0; i < this.singleSeatsBuffer.length; ++i) {
    //   status.seatmap.moduleRef['addStatus'](this.singleSeatsBuffer[i]['mmcid'],"pending");
    // }
    this.printBuffer();

    return bufferAction$.pipe(
      tap(() => {
        this.singleSeatsBuffer = [];
        this.dvmService.viewer.setNodesTag(this.dvmService.viewer.getNodesByTag('seat', 'pending'), 'none');
        this.loaderService.removeProcess("selectseat");
      }),
      catchError(error => {
        console.log(error);
        let errorMessage = error.message.split('%%%')[0];
        let errorText = error.message.split('%%%')[1];
        if (errorMessage === 's:SingleSeatConstraintViolation') {
          console.log('SINGLE SEAT: 2 ERRORS CASE');
          this.dvmService.viewer.unselect(this.singleSeatsBuffer[1]['mmcid']);
          this.singleSeatsBuffer.length = 1;
          this.printBuffer();
          errorMessage = 'IsolatedSeatsNotHandled';
          errorText = 'When making seat selections, you cannot leave a single seat. ' +
            'Please adjust your seating selection to continue.';
        } else {
          this.singleSeatsBuffer = [];
          this.printBuffer();
        }
        this.loaderService.removeProcess("selectseat");
        throw new Error(`${errorMessage}::${errorText}`);
        // this.flowControl.showLoader(false);
      })
    );
  }

  /*
  * SEND BUFFER TO BE RESOLVED WHEN 'REMOVE' ACTION IS ACTIVE
  */
  private sendSingleSeatsRemoveBuffer() {
    const seats = [];
    for (let i = 0; i < this.singleSeatsBuffer.length; ++i) {
      seats.push(this.singleSeatsBuffer[i]['itemid']);
    }
    if (seats.length > 0) {
      // CHECK ISOLATED SEATS INTERNALLY
      // this.printBuffer();
      const isolatedSeats = this.checkPendingIsolatedSeats();
      if (isolatedSeats.length > 0) {
        this.dvmService.viewer.select(this.singleSeatsBuffer[1]['mmcid']);
        this.singleSeatsBuffer.length = 1;
        this.printBuffer();
        const errorMessage = 'IsolatedSeatsNotHandled';
        const errorText = 'When making seat selections, you cannot leave a single seat. ' +
          'Please adjust your seating selection to continue.';
        this.loaderService.removeProcess("selectseat");
        throw new Error(`${errorMessage}::${errorText}`);

        // this.flowControl.showLoader(false);
      } else {
        // CHECK ISOLATED SEATS EXTERNALLY
        return this.connection.discardBasketItems(seats)
      }
    }
  }

  /*
  * SEND BUFFER TO BE RESOLVED WHEN 'ADD' ACTION IS ACTIVE
  */
  private sendSingleSeatsAddBuffer() {
    const seats = [];
    for (let i = 0; i < this.singleSeatsBuffer.length; ++i) {
      seats.push(this.singleSeatsBuffer[i]['seat']);
    }
    if (seats.length > 0) {
      // CHECK ISOLATED SEATS INTERNALLY
      this.printBuffer();
      const isolatedSeats = this.checkPendingIsolatedSeats();
      if (isolatedSeats.length > 0) {
        this.dvmService.viewer.unselect(this.singleSeatsBuffer[1]['mmcid']);
        this.singleSeatsBuffer.length = 1;
        this.printBuffer();
        const errorMessage = 'IsolatedSeatsNotHandled';
        const errorText = 'When making seat selections, you cannot leave a single seat. ' +
          'Please adjust your seating selection to continue.';
        this.modals.throwErrorModal(errorMessage, errorText);
        // this.flowControl.showLoader(false);
        this.loaderService.removeProcess("selectseat");
      } else {
        // CHECK ISOLATED SEATS EXTERNALLY
        return this.seatgeekConnectionService.addSubscriptionsToBasket(this.singleSeatsBuffer[0]['areaid'],
          this.singleSeatsBuffer[0]['pricetype'], seats)
      }

    }
  }

  /*
  * PRINT BUFFER CHANGES IN THE SEATMAP
  */
  public printBuffer() {
    let action;
    let counteraction;
    if (this.singleSeatsAction === 'add') {
      action = 'select';
      counteraction = 'unselect';
    } else if (this.singleSeatsAction === 'remove') {
      action = 'unselect';
      counteraction = 'select';
    }
    const pendingElements = this.dvmService.viewer.getNodesByTag('seat', 'pending');
    if (pendingElements.length > 0 && pendingElements != null) {
      for (let i = 0; i < pendingElements.length; ++i) {
        this.dvmService.viewer[counteraction](pendingElements[i]['id']);
        this.dvmService.viewer.setNodesTag(pendingElements[i]['id'], 'none');
      }
    }
    if (this.singleSeatsBuffer.length > 0) {
      for (let j = 0; j < this.singleSeatsBuffer.length; ++j) {
        this.dvmService.viewer[action](this.singleSeatsBuffer[j]['mmcid']);
        this.dvmService.viewer.setNodesTag(this.singleSeatsBuffer[j]['mmcid'], 'pending');
      }
    }
    console.log('PRINT BUFFER', this.dvmService.viewer.getNodesByTag('seat', 'pending'));
    console.log('ACTION', action, counteraction);
  }

}
