import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { SeatgeekConnectionSeriesService } from 'ngx-seatgeek-connection';
import { AsyncSubject, forkJoin, of, Subject } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { APP_CONFIG } from '../app-configuration';
import { AppConfiguration } from '../app-configuration.model';

@Injectable({
  providedIn: 'root'
})
export class PriceTypeService {
  public seatpricing;
  public seatpricingRenewal;
  public areapricing;

  public priceTypesBySeat;

  public hasMultiplePriceTypes = false;
  public multiplePriceTypes;

  public pricetypesList;
  public customPriceTypes;


  public overridePriceType;

  // public defaultPriceType; // Llenar desde el init si se quiere tener aqui


  constructor(private http: HttpClient,
    @Inject(APP_CONFIG) private appConfig: AppConfiguration) {
  }


  getPriceType(nodeId: string) {

    let priceType;

    if (this.hasMultiplePriceTypes && this.multiplePriceTypes[nodeId]) {
      priceType = null;
    } else if (this.customPriceTypes && this.customPriceTypes.seats[nodeId]) {
      priceType = this.customPriceTypes.seats[nodeId];
    } else if (this.customPriceTypes && this.customPriceTypes.sections[nodeId.split("-")[0]]) {
      priceType = this.customPriceTypes.sections[nodeId.split("-")[0]];
    } else if (this.overridePriceType) {
      priceType = this.overridePriceType;
    } else {
      priceType = this.appConfig.defaultPricetype;
      if (!priceType) {
        throw new Error("No pricetype available");
      }
    }
    return priceType;
  }



}
