import { Inject, Injectable } from '@angular/core';
import { SeatgeekConnectionIdtranslatorService, SeatgeekConnectionSeriesService, SeriesItem } from 'ngx-seatgeek-connection';
import { PriceTypeService } from '../configuration/standard-services/price-type.service';
import { DVMService } from './dvm.service';
import { APP_CONFIG } from "../configuration/app-configuration"
import { AppConfiguration } from "../configuration/app-configuration.model"
import { ModalsService } from './modals.service';
import { LoaderService } from './loader.service';
import { IsolatedSeatsService } from './isolated-seats.service';
import { SeatgeekConnectionService } from './seatgeek-connection.service';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, mergeMap, take } from 'rxjs/operators';



@Injectable({
  providedIn: 'root'
})
export class SeatSelectionService {

  countNew = 0;
  additionalSeatsLimit = this.app_config.addonsConfiguration.addonsMaxSelection;

  addAdditionalSeats(additional) {
    this.additionalSeatsLimit += additional;
    console.log(this.additionalSeatsLimit)
  }

                
  constructor(private translator: SeatgeekConnectionIdtranslatorService,
              private dvmService: DVMService,
              private seatgeekConnectionService: SeatgeekConnectionService,
              private pricing: PriceTypeService,
              private modals: ModalsService,
              private loaderService: LoaderService,
              private isolatedSeatsService: IsolatedSeatsService,
              @Inject(APP_CONFIG) private app_config: AppConfiguration) { }



  selectseat(node, selectedPT) {
    let mmcArea, areaTranslation, areaId, seatId;
    this.translator.translationsHolder.subscribe(
      translationsHolderData => {
        mmcArea = this.dvmService.viewer.getMapId();
        areaTranslation = translationsHolderData.frommmc.areas[mmcArea];
        areaId = areaTranslation.seatgeekId;
        seatId = translationsHolderData.frommmc.seats[node.id];
      });
    
    

    this.isolatedSeatsService.checkResetBuffer(areaId);

    let priceType;
    const element = this.pricing.pricetypesList.find(element => element["b:value"] === selectedPT);
    priceType = element ? element["b:key"] : undefined;


    if (!priceType) {
      // this.flowControl.showLoader(false);
      this.loaderService.removeProcess("selectseat");
      return throwError('%%%The Price Type ' + selectedPT + ' was not found in the pricing description for this series.')
    }

    const action = 'add';
    const seatObject = {'areaid': areaId, 'pricetype': priceType, 'seat': parseInt(seatId, 10), 'id': node.id};
    return this.isolatedseats(seatObject, action);
  };


  unselectseat(node) {
    return this.seatgeekConnectionService.Basket.pipe(
      take(1),
      mergeMap((basketItems: SeriesItem[]) => {
        const itemid: any = basketItems.filter(item => item["productData"]["mmcSeatId"] === node.id)
          .map(item => item.id)[0];
        const action = 'remove';
        const seatObject = {'itemid': itemid, 'id': node.id};
        return this.isolatedseats(seatObject, action).pipe(map(response => response || basketItems));
      })
    );
  }



  private isolatedseats(seatObject, action) {
    if (this.isolatedSeatsService.singleSeatsBuffer.length > 0) {
      return this.isolatedSeatsService.singleSeatHandling(seatObject, seatObject.id, action);
    } else {

      let existIsolatedseats: boolean;

      if (!this.app_config.preventIsolatedSeats) {
        existIsolatedseats = false;
      } else {
        let selectedElements = null
        if (action === "add") {
          selectedElements = Array.of(this.dvmService.viewer.getNodeById(seatObject.id));
        } else if (action === "remove") {
          this.dvmService.viewer.unselect(seatObject.id);
          selectedElements = this.dvmService.viewer.getNodesByState('seat', 'selected');
        }
        const isolatedseats = this.isolatedSeatsService.checkIsolatedSeats(selectedElements, seatObject, action);
        //Logger isolated seats
        existIsolatedseats = !!isolatedseats.length;
      }


      if (!existIsolatedseats) {
        let actionResult$: Observable<any> = null;
        if (action === "remove") {
          actionResult$ = this.seatgeekConnectionService.discardBasketItems(seatObject.itemid);
        } else if (action === "add") {
          this.seatgeekConnectionService.addedSeatsCount$.subscribe(count => {this.countNew = count});
          if (this.countNew < this.additionalSeatsLimit) {
            actionResult$ = this.seatgeekConnectionService.addSubscriptionsToBasket(seatObject.areaid, seatObject.pricetype, [seatObject.seat]);
          } else {
            const ISerrorMessage = '';
            const ISerrorText =
            'There is a limit of ' + this.app_config.addonsConfiguration.addonsMaxSelection + 'additional season tickets per account in this process. If you have questions please call 513-977-5425.';
            return throwError(new Error(ISerrorMessage + "%%%" + ISerrorText));
          }
        }
        return actionResult$.pipe(
          catchError((error) => {
            console.log("ERROR", error)
            const [errorMessage, errorText] = error.message.split("%%%");
            if (errorMessage === "s:SingleSeatConstraintViolation") {
              this.isolatedSeatsService.singleSeatHandling(seatObject, seatObject.id, action);
              throw error;
            } else {
              throw error;
            }
          })
        );
      } else {
        const ISerrorMessage = 'IsolatedSeatsNotHandled';
        const ISerrorText =
        'When making seat selections, you cannot leave a single seat. Please adjust your seating selection to continue.';
        return throwError(new Error(ISerrorMessage + "%%%" + ISerrorText));

      }
    }
  }
}
