import { Component, Inject, Input, OnInit, AfterViewInit } from "@angular/core";
import { StateService, Transition } from "@uirouter/angular";
import {
  SeatgeekConnectionIdtranslatorService,
  SeatgeekConnectionSeriesService,
  SeriesItem,
} from "ngx-seatgeek-connection";
import { Test, Token } from "src/app/variables";
import { APP_CONFIG } from "../../configuration/app-configuration";
import { AppConfiguration } from "../../configuration/app-configuration.model";
import { ColoringService } from "src/app/services/coloring.service";
import { ModalsService } from "src/app/services/modals.service";
import { ClickHandler } from "src/app/handlers/click.handler";
import { LoadHandler } from "src/app/handlers/load.handler";
import { PopoverCreateHandler } from "src/app/handlers/popover-create.handler";
import { PopoverDestroyHandler } from "src/app/handlers/popover-destroy.handler";
import { NavigationHandler } from "src/app/handlers/navigation.handler";
declare var $: any;
import * as $ from "jquery";
import { DVMService } from "src/app/services/dvm.service";
import { ClickIndoorPreviewHandler } from "src/app/handlers/click-indoor-preview.handler";
import { LoaderService } from "src/app/services/loader.service";
import { FlowControlService } from "src/app/services/flow-control.service";

@Component({
  selector: "app-preview-landing",
  templateUrl: "./preview-landing.component.html",
  styleUrls: ["./preview-landing.component.css"],
  providers: [
    ClickHandler,
    LoadHandler,
    PopoverCreateHandler,
    PopoverDestroyHandler,
    NavigationHandler,
    ClickIndoorPreviewHandler,
  ],
})
export class PreviewLandingComponent implements OnInit, AfterViewInit {
  isSelectionAllowed: boolean;
  discardedSeats: Object;
  currentBasket: Object[];

  isRelocation: boolean;

  // public subscriptionCurrentBasket = this.connection.Basket.subscribe(
  //     currentBasket => {
  //         this.currentBasket = [];
  //         if (currentBasket != null) {
  //             for (let i = 0; i < currentBasket.length; ++i) {
  //               if (currentBasket[i]['insertionMode'] === 'Return') {
  //                 this.currentBasket.push(currentBasket[i]);
  //                 // if (this.status.seatmap.moduleRef != null) {
  //                 //   this.status.seatmap.moduleRef['setElementAvailable'](currentBasket[i]['productData']['mmcSeatId']);
  //                 //   this.status.seatmap.moduleRef['select'](currentBasket[i]['productData']['mmcSeatId']);
  //                 // }
  //               }
  //             }
  //         }
  //     },
  //     error => {
  //         console.log(error);
  //         this.currentBasket = [];
  //     }
  // );

  @Input()
  set token(token: string) {
    this.connection.init(token);
  }

  get viewer() {
    return this.dvmService.viewer;
  }

  constructor(
    private connection: SeatgeekConnectionSeriesService,
    private state: StateService,
    private coloring: ColoringService,
    private dvmService: DVMService,
    private modals: ModalsService,
    private clickHandler: ClickHandler,
    private loadHandler: LoadHandler,
    private popoverCreateHandler: PopoverCreateHandler,
    private popoverDestroyHandler: PopoverDestroyHandler,
    private navigationHandler: NavigationHandler,
    private clickIndoorPreviewHandler: ClickIndoorPreviewHandler,
    private translator: SeatgeekConnectionIdtranslatorService,
    private loaderService: LoaderService,
    private flowControl: FlowControlService,
    @Inject(APP_CONFIG) private config: AppConfiguration
  ) {}

  ngAfterViewInit() {
    this.coloring.applyInterfaceColors();
  }

  ngOnInit() {
    this.discardedSeats = {};
    this.isSelectionAllowed = this.state.$current.data.isSelectionAllowed;
    this.loaderService.addProcess("previewApp");
    this.connection.getPriceTypes().subscribe(
      (response) => {
        if (response == null) {
          $("#redirectModal").modal({ backdrop: "static", keyboard: false });
          return null;
        } else {
          this.translator.translationsHolder.subscribe((translationsHolderData) => {
            this.connection.getBasketItems().subscribe((basketItems: SeriesItem[]) => {
              console.log(basketItems);
              this.currentBasket = basketItems
                ? basketItems.filter((item: SeriesItem) => item.insertionMode === "Return")
                : [];
              this.isRelocation = this.currentBasket.length > 0;
            });
            this.modals.infoModal();
          });
          this.loaderService.removeProcess("previewApp");
          return response;
        }
      },
      (error) => {
        this.loaderService.removeProcess("previewApp");
        return null;
      }
    );

    // this.connection.queryTransaction().subscribe(
    //   data => {
    //     console.log(data['Client']);
    //     const clientGuid = data['Client']['ClientDetails']['b:Id'];
    //     this.connection.getCustomerExtraFields(clientGuid).subscribe(
    //       response => {
    //         console.log(response);
    //         if (response['em:StringField2']) {
    //           this.flowControl.appointmentTime = response['em:StringField2'];
    //           console.log(this.flowControl.appointmentTime);
    //         }
    //         // if (response['em:DoubleField1']) {
    //         //   this.flowControl.maxAddons = response['em:DoubleField1'];
    //         // }
    //       }
    //     );
    //   }
    // );

    this.dvmService.restartDVM();
  }
}
