import { Injectable, Inject } from '@angular/core';
import { DVMService } from '../services/dvm.service';
import { GeneralHandler } from './general.handler';
import { DVM_CONFIG } from "../configuration/dvm-configuration";
import { APP_CONFIG } from "../configuration/app-configuration"
import { AppConfiguration } from "../configuration/app-configuration.model"
import { DVMConfiguration } from "../configuration/dvm-configuration.model";
import { FlowControlService } from '../services/flow-control.service';
import { SeatgeekConnectionSeriesService, SeatgeekConnectionIdtranslatorService, SeriesItem } from 'ngx-seatgeek-connection';
import { IsolatedSeatsService } from '../services/isolated-seats.service';
import { ModalsService } from '../services/modals.service';
import { PriceTypeService } from '../configuration/standard-services/price-type.service';
import { LoaderService } from '../services/loader.service';
import { SeatgeekConnectionService } from '../services/seatgeek-connection.service';
import { SeatSelectionService } from '../services/seat-selection.service';

@Injectable()
export class ClickSeatselectionHandler extends GeneralHandler {
    countNew = 0;
    additionalSeatsLimit = this.app_config.seatsMaxSelection;
    multiplePriceTypes = false;

    constructor(protected dvmService: DVMService,
                private flowControl: FlowControlService,
                private connection: SeatgeekConnectionSeriesService,
                private translator: SeatgeekConnectionIdtranslatorService,
                private isolatedSeatsService: IsolatedSeatsService,
                private modals: ModalsService,
                private pricing: PriceTypeService,
                private loaderService: LoaderService,
                private seatSelectionService: SeatSelectionService,
                @Inject(APP_CONFIG) private app_config: AppConfiguration,
                @Inject(DVM_CONFIG) private dvmConfig: DVMConfiguration) {
        super('click', dvmService);
    }

    sectionClickHandler(node, viewer) {
        if (node.state === 'available') {
          let generalAdmissionAreas = this.app_config.generalAdmissionAreas;
          if (generalAdmissionAreas.indexOf(node.id) >= 0) {
              this.modals.currentGeneralAdmissionArea = node.id;
              this.modals.generalAdmissionModal();
          } else {
            const loadOptions = JSON.parse(JSON.stringify(this.dvmConfig));
            loadOptions.map_id = node.id;
            loadOptions.venue_id = this.dvmService.viewer.getVenueId();
            this.dvmService.loadMap(loadOptions);
          }
        }
    }

    seatClickHandler(node, viewer) {
      if (node.state === 'available') {
        this.loaderService.addProcess("selectseat");

          let priceType = this.pricing.getPriceType(node.id);
        
          if(this.flowControl.relocationSeatsCount >= 0) {
            priceType = 'Season Renewal New';
            
            if(this.checkDate()){
              this.modals.throwErrorModal('Action Not Allowed', 'This time you can only relocate your seats.');
              this.loaderService.removeProcess("selectseat");
              return;
            }

          }

          if (priceType) {
            this.seatSelectionService.selectseat(node, priceType).subscribe(
              _ => {
                this.loaderService.removeProcess("selectseat");
              },
              error => {
                let errorMessage;
                let errorText;
                if (error.message) {
                  [errorMessage, errorText] = error.message.split("%%%");
                } else {
                  [errorMessage, errorText] = error.split("%%%");;
                }
                this.modals.throwErrorModal(errorMessage, errorText);
                this.loaderService.removeProcess("selectseat");
              });
          } else { //Multiple pricetypes case
            //! PENDING
            // Attach to flow control
          }
        } else if (node.state === 'selected') {
          this.loaderService.addProcess("selectseat");
          this.seatSelectionService.unselectseat(node).subscribe(
            _ => {
              this.loaderService.removeProcess("selectseat");
            },
            error => {
              console.log("error", error)
              const [errorMessage, errorText] = error.message.split("%%%");
              this.modals.throwErrorModal(errorMessage, errorText);
              this.loaderService.removeProcess("selectseat");
            }
          );
        }
    }

    private checkDate() {
     
      const fechaLimite = new Date('2025-02-06T00:00:00'); // 6 de febrero de 2025 a las 00:00
      const fechaActual = new Date();
      
      return fechaActual >= fechaLimite;

    }

    protected handle(obj) {
        // Close popovers
        const tooltip: HTMLElement = document.querySelector('#tooltip');
        tooltip.removeAttribute('data-show');

        // Handle
        const viewer = obj.instance;
        const nodes = obj.nodes;
        if (nodes.length) {
            if (nodes[0].type === 'section') {
                if (this.flowControl.suitesHash[nodes[0].id]) {
                    this.dvmService.load3DView(nodes[0].id);
                    console.log(this.dvmService.viewer3d);
                } else {
                    this.sectionClickHandler(nodes[0], viewer);
                }
            } else if (nodes[0].type === 'seat') {
                this.seatClickHandler(nodes[0], viewer);
            }
        }
    }
}
